<template>
  <b-card>
    <b-row class="d-flex align-items-center">
      <b-col>
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          :href="downloadUrl"
          target="_blank"
          :disabled="!sdate || !edate"
        >
          <FeatherIcon icon="DownloadIcon" />
          Raporu İndir
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormDatepicker, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ContractedCredits',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
  },
  data() {
    return {
      sdate: null,
      edate: null,
    }
  },
  computed: {
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/reports/sales/contracted_credits_export/excel?token=${localStorage.getItem('downloadToken')}&sdate=${this.sdate}&edate=${this.edate}`
    },
  },
}
</script>
